@import '~@digicert/dcone-common-ui/src/styles/variables';
@import '~@digicert/dcone-common-ui/src/styles/utils/alignment';
@import '~@digicert/dcone-common-ui/src/styles/utils/breakpoints';

$welcome-max-width: 1024px;

.welcome {
  font-family: $font-regular;

  .sectionTitle1 {
    font-size: 24px;
    font-weight: $font-weight-regular !important;
  }

  .header {
    background: linear-gradient(90deg, #2BB9C8 1%, $electric-blue 49.5%, $blue 100%);
    background-size: cover;
    color: $white;
    height: auto;
    min-height: 250px;
    padding: $spacing-3;
    text-align: center;
    position: relative;
    display: grid;
    align-items: center;

    .headline {
      color: $white;
      font-size: 48px;
      font-family: 'Fjalla One', 'Segoe UI', 'Helvetica Neue', sans-serif !important;
      text-transform: uppercase;
      line-height: 50px;
      display: inline;
    }

    p {
      margin: $spacing-2 auto;
      max-width: $welcome-max-width;
      font-size: 20px;
    }
  }

  .content {
    padding: $spacing-7 $spacing-6;
    max-width: $welcome-max-width;
    margin: 0 auto;
  }

  .noAppAccess {
    max-width: $welcome-max-width;
    margin: 0 $spacing-5;
  }

  .actions {
    border-bottom: 1px solid $light-gray;
    padding: 0 $spacing-5 $spacing-6 $spacing-5;

    .description {
      margin: $spacing-3 0;
    }

    .info {
      margin: $spacing-5 0 0 0;
      color: $medium-gray;
    }
  }

  .productPromos {
    padding: $spacing-5 $spacing-5;
    max-width: $welcome-max-width;

    .container {
      align-items: center;
      display: grid;
      grid-template-columns: auto;
      grid-row-gap: $spacing-4;
      @include breakpoint(xlarge) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: $spacing-7
      }
    }

    .promo {
      margin: $spacing-2 0 $spacing-4 0;
      .title {
        align-items: center;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: $spacing-1;
        margin: 0;
      }
      .description {
        color: $medium-gray;
        margin: $spacing-1 0 0 $spacing-2;
      }
      .check {
        color: $blue;
      }
    }

    .learnMore {
      display: inline-block;
      margin-top: $spacing-5
    }
  }
}