@import "~@digicert/dcone-common-ui/src/styles/variables";

.tenants {
  .header {
    margin: 0 0 $spacing-2 0;
  }

  .actions {
    margin: 0 0 $spacing-2 0;
  }

  .table {
    height: auto;
    margin: $spacing-3 -#{$spacing-4} 0 -#{$spacing-4};
    position: relative;
  }
  .identifier {
    color: $medium-gray;
  }
}
