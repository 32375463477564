@import '~@digicert/dcone-common-ui/src/styles/variables';
@import '~@digicert/dcone-common-ui/src/styles/utils/rem-calc';
@import '~@digicert/dcone-common-ui/src/styles/utils/breakpoints';

.accountDetail {
  :global(#content_wrap) {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  }

  .headerStatusWrap {
    margin-left: #{-$spacing-4};
    margin-right: #{-$spacing-4};
    margin-top: -17px;
    position: relative;
  }

  .editAccountButton {
    svg {
      margin-top: -6px;
    }
  }

  .button {
    display: inline-block;
  }

  .sideRailRecentHistory {
    padding: $spacing-2 $spacing-2;
    background: $off-white;
  }

  .sideRailAction {
    padding: $spacing-3 $spacing-2;
  }

  .sideRailList {
    border-bottom: 1px solid $light-gray;
    font-size: rem-calc(14px);
    list-style: none;
    position: relative;
    padding: $spacing-2 0;

    &:last-child {
      border: none;
      margin: 0;
    }
  }

  :global(.ant-collapse-content) {
    padding: 0 $spacing-2 !important;
  }

  :global(.ant-collapse-content-box) {
    padding: $spacing-3 0 0 0 !important;
  }

  .sideRail {
    z-index: map-get($z-index, drop-down);
  }

  .sideRailListTitle {
    color: $medium-gray;
    display: block;
    font: $font-regular;
    font-size: rem-calc(13px);
    margin: 0;

    &:not(:first-of-type) {
      margin-top: $spacing-3;
    }
  }

  .sideRailListSubTitle {
    color: $dark-gray;
    display: block;
    font: $font-medium;
    font-size: rem-calc(14px);
    font-weight: $font-weight-medium;
  }

  .sectionsSideNavWrap {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
  }

  .sections {
    flex: 1 1 auto;
    padding: $spacing-6 $spacing-3;
  }

  .sideNav {
    flex: 0 0 200px;
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 10px;
    background-color: $white;
  }

  .container {
    background-color: $white;
  }

  .title {
    padding: 30px $spacing-1 30px $spacing-4;
    color: $dark-gray;
    background-color: $white;
    display: inline-block;
  }

  .banner {
    padding: 0px $spacing-1 30px $spacing-4;
    display: inline-block;
  }

  .subTitle {
    padding-bottom: $spacing-2;
  }

  .licenses {
    .licenseButton {
      margin-bottom: $spacing-2;
    }
  }

  .licenseCardsContainer {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: $spacing-2;
    grid-row-gap: $spacing-2;

    @media (min-width: 1440px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1920px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 2560px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  .notActive {
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
  }

  :global(.ant-collapse).collapse {
    & > :global(.ant-collapse-item).licenseFormPanel {
      & > :global(.ant-collapse-header) {
        color: $dark-gray;

        & :global(.ant-collapse-arrow) {
          display: none;
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: $spacing-2;
    justify-content: start;
  }

  .info {
    h4 {
      font-weight: normal;
    }

    p {
      margin-bottom: 0;
    }
  }

  .generalInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $spacing-2;
    margin-bottom: $spacing-2;

    .label {
      color: $medium-gray;
      margin-bottom: 0;
    }

    .description {
      font-family: 'Roboto-Regular', 'Roboto', sans-serif;
      font-size: rem-calc(14px);
      font-weight: 450;
      color: $dark-gray;
      margin-bottom: $spacing-3;
    }
  }
}

.note {
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-size: rem-calc(14px);
  font-weight: 550;
  color: $dark-gray;
  margin-bottom: 0;
  margin-top: $spacing-3;
}

.list {
  padding-left: $spacing-2;
}
